import { of, forkJoin, throwError } from 'rxjs'
import { mergeMap, catchError, delay, map } from 'rxjs/operators'
import { serialize } from 'helpers/index.js'
import Fetch from '../fetch'
import DataHelper from 'framework/helpers/data'
import {
    M1UserSubscriptionFactory,
    M1PartnerSubscriptionFactory,
    updateSubscriptionsEntitlements,
} from '../factories/subscription'
import { parseTransactionResponse } from '../helpers/transaction'
import { M1TransactionResponse } from '../models/M1TransactionResponse'
import { M1ApiVersion } from '../models/M1ApiVersion'
import { IapProduct } from 'models'
import VoltError from 'VoltError'
import AuthType from 'framework/helpers/auth'
import Constants from 'api-constants'
import { cloneDeep, isEmpty } from 'lodash'

/**
 * This constant defines the value to apply to delay the transaction response (subscribe, unsubscibe)
 * Used for version of MarketONE API where the User Management is decoupled from the subscription in terms of micro services
 * This architecture brings some delay. Although refactoring deeply our UI, it is better to just apply...
 * Not needed to use a configuration parameter for the moment
 */
const DEFAULT_TRANSACTION_DELAY_MS = 2000

/**
 * Deal with Purchase (data retrieval, purchase and subscription actions)
 * Documentation is available here : https://developer.m1amdocs.com/api/
 */
export default class PurchaseApi extends Fetch {
    static TAG = 'PurchaseApi'

    static defaultConfig = {
        pageSize: 50,
    }

    getCatalog = () => {
        console.log('get catalog api called')
        let url
        const accountId = DataHelper.getInstance().getAccountId()
        const apiVersion = M1ApiVersion.getApiVersion(this.config)
        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${this.config.urls.storefrontUrl}/catalog?accountId=${accountId}`
        } else {
            url = `${this.config.urls.apiUrl}/catalog?accountId=${accountId}`
        }
        return this.fetch({
            url,
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            method: 'GET',
            log: `[GET CATALOG][${apiVersion}]`,
        }).pipe(
            map(({ response }) => {
                return response
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of([])
            })
        )
    }

    /*
        We should only allow user to purchase trial offers for first time.
        Once user purchsase trial, we should not allow user to purchase trial again
    */
    _isOfferPurchasable = (product, products, catalogProducts) => {
        let trialGroup = product.trialGroup
        if (trialGroup === '') {
            return true
        }
        let productIds = products?.reduce((pIds, item) => {
            if (item.trialGroup === trialGroup) {
                pIds.push(item.id)
            }
            return pIds
        }, [])
        let catalogProductList = catalogProducts.filter(
            (item) => productIds.includes(item.productId) && item?.billingOptions?.length
        )
        /* if we have single product in catalog, it is purchasable */
        if (catalogProductList?.length === 1) {
            return true
        }

        /* if we have two products in catalog from same trialGroup, only trial will be purchasable */
        return !!product.offers?.[0]?.trial?.cycles
    }

    _catalogProductListContainsNoBillingOptions = (catalogProducts, productIds) => {
        let catalogProductListForTrialGroup = catalogProducts.filter((item) =>
            productIds.includes(item.productId)
        )
        // Check if any product does not have billing options
        const containsNoBillingOption = catalogProductListForTrialGroup.some(
            (item) => !item?.billingOptions?.length
        )
        return containsNoBillingOption // Return true if any product lacks billing options
    }

    _isOfferContainingBillingIDPurchasable = (product, products, catalogProducts) => {
        let trialGroup = product.trialGroup
        if (trialGroup === '') {
            return true
        }
        let productIds = products?.reduce((pIds, item) => {
            if (item.trialGroup === trialGroup) {
                pIds.push(item.id)
            }
            return pIds
        }, [])
        let catalogProductList = catalogProducts.filter(
            (item) => productIds.includes(item.productId) && item?.billingOptions?.length
        )
        /* if we have any product for one single trialgroup without billingplanId, it means one trial offer has been consumed. So we will filter out all trial offers*/
        if (this._catalogProductListContainsNoBillingOptions(catalogProducts, productIds)) {
            return !product.offers?.[0]?.trial?.cycles
        }
        /* if we have single product in catalog, it is purchasable */
        if (catalogProductList?.length === 1) {
            return true
        }

        /* if we have two products in catalog from same trialGroup, only trial will be purchasable */
        return !!product.offers?.[0]?.trial?.cycles
    }

    productFilterBasedOnId = (products, userSubscriptions, catalogProducts) => {
        let subscribedAndCatalogProducts = [...catalogProducts]

        // We need to add sunscription products if not found in catalog
        userSubscriptions.map((item) => {
            let isSubscriptionPresentInCatalog = subscribedAndCatalogProducts.find(
                (product) => item.productId === product.productId
            )
            if (isEmpty(isSubscriptionPresentInCatalog)) {
                subscribedAndCatalogProducts.push({
                    productId: item.productId,
                })
            }
        })
        let filteredProduct = []
        products.map((product) => {
            let promotionsMap = {}
            subscribedAndCatalogProducts.map((catalogProduct) => {
                if (product?.id === catalogProduct?.productId) {
                    let catalogProductOffers = []
                    if (catalogProduct?.billingOptions?.length) {
                        catalogProduct?.billingOptions?.map((option) => {
                            catalogProductOffers.push(option?.billingPlanId)
                            let key = option?.billingPlanId

                            promotionsMap[key] = !isEmpty(promotionsMap[key])
                                ? [...(promotionsMap[key] || []), option?.campaignId]
                                : [option?.campaignId]
                        })
                        let catalogOffers = product?.offers?.filter((offer) =>
                            catalogProductOffers.includes(offer.id)
                        )

                        catalogOffers?.map((offer) => {
                            let promotionList = product?.promotions?.filter((p) => {
                                let key = offer?.id
                                let keyList = promotionsMap[key]
                                if (keyList.includes(p?.promotion_code)) {
                                    return true
                                } else {
                                    return false
                                }
                            })
                            offer.promotions = promotionList
                            //conditionally calling the isPurchasable func based on flag
                            offer.isPurchasable = this.config.filteredProductBySameTrialGroupName
                                ? this._isOfferContainingBillingIDPurchasable(
                                      product,
                                      products,
                                      catalogProducts
                                  )
                                : this._isOfferPurchasable(product, products, catalogProducts)
                        })

                        if (catalogOffers?.length) {
                            product.offers = catalogOffers
                        } else {
                            /* if offers not present in catalog, make it non purchasable and add product promotions to offers*/
                            product.offers.map((i) => {
                                i.isPurchasable = false
                                i.promotions = product?.promotions
                            })
                        }
                    }

                    // filter catalog offers based on catalog offers
                    product.catalogOffers = product?.catalogOffers?.filter((item) => {
                        let offerMatched = product.offers.find((offer) => {
                            const isSameBillingPlan = offer?.offerId === item?.BillingPlatformId
                            if (
                                !offer?.promotions?.[0]?.promotion_code &&
                                !item?.CampaignPlatformId
                            ) {
                                return isSameBillingPlan
                            }
                            const isSamePromotion =
                                offer?.promotions?.[0]?.promotion_code === item?.CampaignPlatformId

                            return isSameBillingPlan && isSamePromotion
                        })
                        return !isEmpty(offerMatched)
                    })

                    product['canUpgradeTo'] = catalogProduct.canUpgradeTo || []
                    product['canDowngradeTo'] = catalogProduct.canDowngradeTo || []
                    filteredProduct.push(product)
                }
            })
        })
        return filteredProduct
    }
    /**
     * Retrieves all Subscription products
     *
     * @param {Number} [pageSize=15] The page size of each api request
     *
     * @returns {Observable<SubscriptionsList>}
     */
    getSubscriptions(pageSize = 15) {
        this.logger.info(PurchaseApi.TAG, `Getting all user subscriptions...`)
        let updatedProduct = []
        let apiCalls = [
            // GET Package descriptions from the proxy
            this.metaApi.getAllSubscriptions(),
            // THEN GET entitlements statuses for the USER
            this._getUserSubscriptions(),
            // Then GET Partner Subscription to check if the application need to be activated or not
            this._getPartnerSubscriptions(),
        ]
        if (this.config.eligibilityEnabled) {
            apiCalls.push(this.getCatalog())
            return forkJoin(apiCalls).pipe(
                mergeMap(
                    ([
                        proxySubscriptions,
                        userSubscriptions,
                        partnerSubscriptions,
                        catResponse,
                    ]) => {
                        let activeUserSubscriptions = userSubscriptions.filter(
                            (item) =>
                                item.subscriptionStatus !== 'CANCELLED' &&
                                item.subscriptionStatus !== 'EXPIRED'
                        )
                        let inactiveSubscriptions = userSubscriptions.filter(
                            (item) =>
                                item.subscriptionStatus == 'CANCELLED' ||
                                item.subscriptionStatus == 'EXPIRED'
                        )
                        updatedProduct = this.productFilterBasedOnId(
                            proxySubscriptions,
                            activeUserSubscriptions,
                            catResponse
                        )
                        return of({
                            products: updateSubscriptionsEntitlements(
                                updatedProduct,
                                activeUserSubscriptions,
                                partnerSubscriptions,
                                inactiveSubscriptions
                            ),
                            isComplete: true,
                            partnerSubscriptionProduct: partnerSubscriptions,
                        })
                    }
                )
            )
        } else {
            return forkJoin(apiCalls).pipe(
                mergeMap(([proxySubscriptions, userSubscriptions, partnerSubscriptions]) => {
                    return of({
                        products: updateSubscriptionsEntitlements(
                            proxySubscriptions,
                            userSubscriptions,
                            partnerSubscriptions
                        ),
                        isComplete: true,
                        partnerSubscriptionProduct: partnerSubscriptions,
                    })
                })
            )
        }
    }

    /**
     * From the productId, retrieve subscription data, and pass it to factory
     * to update it with entitlement status
     *
     * @param {Array<String>} productIds An array containing the product identifier
     * as first and unique element
     *
     * @returns {Observable<Array<Subscription>>} A list of {@link Subscription} products
     */
    getSubscriptionsData(productIds) {
        this.logger.info(PurchaseApi.TAG, `Getting User subscriptions...`)
        let updatedProduct = []
        return forkJoin([
            // GET Package descriptions from the proxy
            this.metaApi.getSubscriptionsById({ ids: productIds }),
            // THEN GET entitlements statuses for the USER
            this._getUserSubscriptions(),
            // Then GET Partner Subscription to check if the application need to be activated or not
            this._getPartnerSubscriptions(),
            this.getCatalog(),
        ]).pipe(
            mergeMap(
                ([proxySubscriptions, userSubscriptions, partnerSubscriptions, catResponse]) => {
                    updatedProduct = this.productFilterBasedOnId(
                        proxySubscriptions,
                        userSubscriptions,
                        catResponse
                    )
                    return of(
                        updateSubscriptionsEntitlements(
                            updatedProduct,
                            userSubscriptions,
                            partnerSubscriptions
                        )
                    )
                }
            )
        )
    }

    /**
     * Request to purchase a subscription product
     * @param {String} productId
     * @param {String} offerId
     * @param {Object} purchaseData
     * @param {Object} [purchaseData.profile] User Profile (not used)
     * @param {Object} [purchaseData.paymentMethod] Payment method to use for Purchase (e.g Pay on bill, Credit Card, etc..)
     * @param {Object} [purchaseData.billingPlan] Billing Plan to use for Purchase (Buy for 1 month, 6 month, 1 year and pay every Month or for a Year, etc..)
     * @param {String} [purchaseData.iapReceipt] (Optionally) iapReceipt is used to validate app store purchase. MarketONE has decide to reuse the purchase API to simplify the design
     * @param {String} [purchaseData.iapAppstore] (Optionally) iapAppStore is used to indicate which store is used (GOOGLE, APPLE, AMAZON)
     * @param {String} [purchaseData.couponId] (Optionally) couponId is used to validate coupon
     * @param {Boolean} [purchaseData.verifyCoupon] (Optionally) additional body parameter to verify coupon without purchasing
     * @param {Boolean} [purchaseDryrun]
     *
     * @returns {Observable}
     */
    subscribe(
        productId,
        offerId,
        purchaseData,
        iapReceipt,
        iapAppStore,
        metadataInfo,
        purchaseDryrun
    ) {
        if (productId) {
            this.logger.info(
                PurchaseApi.TAG,
                `Subscribing to [product: ${productId}][Offer: ${offerId}] ...`
            )
        }
        const isAppStorePurchase = !!iapReceipt
        const { profile = {}, paymentMethod, couponId = false, verifyCoupon, items } = purchaseData
        const { customerId } = profile

        const billingPlanId = offerId
        let myPaymentMethod = paymentMethod
        if (!paymentMethod && !isAppStorePurchase) {
            const { paymentMethods = [] } = profile

            myPaymentMethod = paymentMethods.find((elt) => elt.defaultPaymentMethod && elt.active)
            if (!verifyCoupon && !myPaymentMethod) {
                return throwError(
                    new VoltError(VoltError.codes.PURCHASE_FAILED_DUE_TO_MISSING_PAYMENT_METHOD, {
                        extraLog:
                            'Neither selected payment method by the user NOR active default payment provided',
                    })
                )
            }
        }

        if (!billingPlanId) {
            this.logger.warn(
                PurchaseApi.TAG,
                'No Billing Plan provided. SHOULD throw an error BUT allowed for the moment by a STB'
            )
        }

        // Do not over-complexify for the moment the refactor, handle it as follow pending more details
        let url
        const apiVersion = M1ApiVersion.getApiVersion(this.config)
        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${this.config.urls.storefrontUrl}/v2/subscription/purchase`
        } else {
            url = `${this.config.urls.apiUrl}/user/subscription/purchase`
        }

        if (purchaseDryrun) {
            url = `${url}?dryrun=false`
        }

        let body = {}
        if (isEmpty(items)) {
            body = {
                productId: productId,
                billingOption: {
                    billingPlanId,
                },
            }
            if (couponId) {
                body.billingOption.campaignId = couponId
            }
        } else {
            body = {
                billingPlanId: billingPlanId,
                items: items,
            }
        }

        if (verifyCoupon) {
            url = `${url}?dryrun=${verifyCoupon}`
        }

        if (isAppStorePurchase) {
            // For IAP Purchase, there is a need of account id rather than paymentMethodId as the user is paying via AppStore
            body = {
                ...body,
                accountId: customerId,
                metadata: {
                    iapReceipt,
                    iapReceiptProvider: (() => {
                        switch (iapAppStore) {
                            case IapProduct.IAP_APP_STORE.APPLE:
                                return 'APPLE'
                            case IapProduct.IAP_APP_STORE.AMAZON:
                                return 'AMAZON'
                            default:
                            case IapProduct.IAP_APP_STORE.GOOGLE:
                                return 'GOOGLE'
                        }
                    })(),
                },
            }
        } else {
            // Otherwise use payment method
            body = {
                ...body,
                accountId: paymentMethod?.internalProviderAccountId ?? customerId,
                paymentMethodId: myPaymentMethod?.id,
            }
        }
        if (metadataInfo) {
            body = {
                ...body,
                metadata: { ...metadataInfo },
            }
        }

        return this.fetch({
            url,
            body,
            headers: {
                'Content-Type': 'application/json',
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            method: 'POST',
            log: verifyCoupon
                ? `[PURCHASE DRY RUN TO VERIFY COUPON][${apiVersion}]`
                : `[PURCHASE SUBSCRIPTION][${apiVersion}]`,
        }).pipe(
            mergeMap(({ response }) => {
                const transaction = parseTransactionResponse(response, apiVersion)

                if (verifyCoupon) {
                    this.logger.info(
                        PurchaseApi.TAG,
                        `[Dry run for verifying coupon succeeded] ${transaction.toString()}`
                    )
                    return of({
                        nextTransaction: response?.nextTransaction,
                        subscription: transaction?.subscription,
                        transaction: transaction?.transaction,
                    })
                }

                if (transaction.status !== M1TransactionResponse.STATUS.activated) {
                    return throwError(
                        new VoltError(VoltError.codes.PURCHASE_FAILED, {
                            extraLog: `[Transaction failed] ${transaction.toString()}`,
                        })
                    )
                }
                this.logger.info(
                    PurchaseApi.TAG,
                    `[Transaction succeeded] ${transaction.toString()}`
                )

                of(null).pipe(
                    // As MarketONE API V2 is splitted into several microservices, there could be a delay before getting user entitlements updated, not ideal solution better to do it here
                    delay(
                        apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2
                            ? DEFAULT_TRANSACTION_DELAY_MS
                            : 0
                    ),
                    mergeMap(() => {
                        return this._refreshTokenAfterTransaction()
                    })
                )
                return of({
                    subscription: transaction?.subscription,
                    transaction: transaction?.transaction,
                })
            }),
            catchError((error) => {
                if (error?.acr_value) {
                    return throwError(
                        new VoltError(error, {
                            inheritedError: error,
                        })
                    )
                }

                return throwError(
                    new VoltError(
                        (() => {
                            switch (error.code) {
                                case VoltError.codes.UNKNOWN_API_ERROR.code:
                                    return isAppStorePurchase
                                        ? VoltError.codes.PURCHASE_VALIDATION_IAP_RECEIPT_FAILED
                                        : VoltError.codes.PURCHASE_FAILED
                                case VoltError.codes.ELIGIBILITY_FAILED.code:
                                    return VoltError.codes.ELIGIBILITY_FAILED
                                default:
                                    return error
                            }
                        })(),
                        {
                            inheritedError: error,
                        }
                    )
                )
            })
        )
    }

    /**
     * Request to unsubscribe a subscription product
     * @param {String} productId
     * @param {String} offerId
     *      * @param {String} transactionId
     * @param {String} transactionAlternativeId
     * @param {String} reasonCode
     *
     * @returns {Observable}
     */
    unsubscribe(productId, offerId, transactionId, transactionAlternativeId, reasonCode) {
        this.logger.info(
            PurchaseApi.TAG,
            `Unsubscribing from [product: ${productId}][Offer: ${offerId}] ...`
        )
        if (!transactionAlternativeId && !transactionId) {
            return throwError(
                new VoltError(VoltError.codes.UNSUBSCRIBE_FAILED, {
                    extraLog: `Cannot proceed to cancellation because transactionId is null`,
                })
            )
        }

        // Do not over-complexify for the moment the refactor, handle it as follow pending more details
        let url
        const apiVersion = M1ApiVersion.getApiVersion(this.config)
        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${
                this.config.urls.storefrontUrl
            }/subscription/${transactionAlternativeId}/cancel${serialize({
                reason_code: reasonCode ?? this._getCancelReason(),
            })}`
        } else {
            url = `${this.config.urls.apiUrl}/user/subscription/${transactionId}/cancel${serialize({
                reason_code: reasonCode ?? this._getCancelReason(),
            })}`
        }

        return this.fetch({
            url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            method: 'POST',
            log: `[CANCEL SUBSCRIPTION][${apiVersion}}`,
        }).pipe(
            mergeMap(({ response }) => {
                const transaction = parseTransactionResponse(response, apiVersion)
                if (!transaction.productId) {
                    // For the moment we consider as unsubscribe succeeded if response from the backend is consistent
                    // Heuristic : use presence of productId in the response
                    return throwError(
                        new VoltError(VoltError.codes.UNSUBSCRIBE_FAILED, {
                            extraLog: `[Unsubscribe failed] ${transaction.toString()}`,
                        })
                    )
                }
                this.logger.info(
                    PurchaseApi.TAG,
                    `[Unsubscribe succeeded] ${transaction.toString()}`
                )

                return of(null).pipe(
                    // As MarketONE API V2 is splitted into several microservices, there could be a delay before getting user entitlements updated, not ideal solution better to do it here
                    delay(
                        apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2
                            ? DEFAULT_TRANSACTION_DELAY_MS
                            : 0
                    ),
                    mergeMap(() => {
                        return this._refreshTokenAfterTransaction()
                    })
                )
            }),
            catchError((error) =>
                throwError(
                    new VoltError(
                        (() => {
                            switch (error.code) {
                                case VoltError.codes.OPERATION_ALREADY_DONE.code:
                                    return VoltError.codes.UNSUBSCRIBE_ALREADY_DONE
                                case VoltError.codes.UNKNOWN_API_ERROR.code:
                                    return VoltError.codes.UNSUBSCRIBE_FAILED
                                default:
                                    return error
                            }
                        })(),
                        {
                            inheritedError: error,
                        }
                    )
                )
            )
        )
    }

    /**
     * This method needs to be called after the purchase of a subscription.
     * Some external OTT Package could require an activation to be available (like Netflix)
     * This API allows to regenerate Activation CODE or trigger a mail to send to the user
     * to follow activation procedure (Email, Redeem Code/Deeplink to launch in or application)
     * @param {String} productId
     * @param {String|Number} transactionId
     *
     * @returns {Observable}
     */
    activateSubscription({ productId, activationId }) {
        this.logger.info(PurchaseApi.TAG, `Re-provisioning [product: ${productId}] ...`)
        this.logger.trace(PurchaseApi.TAG, `...with [activationId: ${activationId}] ...`)

        if (!activationId) {
            return throwError(
                new VoltError(VoltError.codes.SUBSCRIPTION_ACTIVATION_FAILED, {
                    extraLog: 'Cannot proceed to cancellation because transactionId is null',
                })
            )
        }

        // Do not over-complexify for the moment the refactor, handle it as follow pending more details
        let url
        const apiVersion = M1ApiVersion.getApiVersion(this.config)
        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${this.config.urls.storefrontUrl}/partnerSubscription/${activationId}/reprovision`
        } else {
            url = `${this.config.urls.apiUrl}/user/partnerSubscription/${activationId}/reprovision`
        }

        return this.fetch({
            url,
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            method: 'POST',
            log: `[REPROVISION/ACTIVATE SUBSCRIPTION][${apiVersion}]`,
        }).pipe(
            mergeMap(({ response }) => {
                // Cannot be tested yet as Package available for activation are not available yet in Market One instance
                this.logger.info(PurchaseApi.TAG, `[ACTIVATE SUBSCRIPTION] Reprovision Succeeded`)
                return of(true)
            }),
            catchError((error) =>
                throwError(
                    new VoltError(VoltError.codes.SUBSCRIPTION_ACTIVATION_FAILED, {
                        inheritedError: error,
                    })
                )
            )
        )
    }

    /**
     * @param {string} autoBillId
     * */
    reactivateSubscription({ autoBillId }) {
        let url
        const apiVersion = M1ApiVersion.getApiVersion(this.config)
        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${this.config.urls.storefrontUrl}/subscription/${autoBillId}/reactivate`
        } else {
            url = `${this.config.urls.apiUrl}/user/subscription/${autoBillId}/reactivate`
        }
        return this.fetch({
            url,
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            method: 'POST',
            log: `[REACTIVATE SUBSCRIPTION][${apiVersion}]`,
        }).pipe(
            mergeMap(({ response }) => {
                this.logger.info(PurchaseApi.TAG, `[REACTIVATE SUBSCRIPTION] Succeeded`)
                return of(null).pipe(
                    // As MarketONE API V2 is splitted into several microservices, there could be a delay before getting user entitlements updated, not ideal solution better to do it here
                    delay(
                        apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2
                            ? DEFAULT_TRANSACTION_DELAY_MS
                            : 0
                    ),
                    mergeMap(() => {
                        return this.getSubscriptions()
                    })
                )
            }),
            catchError((error) =>
                throwError(
                    new VoltError(VoltError.codes.SUBSCRIPTION_REACTIVATION_FAILED, {
                        inheritedError: error,
                    })
                )
            )
        )
    }

    /**
     * @param {string} autoBillId
     * */
    pauseSubscription({ autoBillId, pauseReasonCode }) {
        let url
        const apiVersion = M1ApiVersion.getApiVersion(this.config)
        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${this.config.urls.storefrontUrl}/subscription/${autoBillId}/pause`
        } else {
            url = `${this.config.urls.apiUrl}/user/subscription/${autoBillId}/pause`
        }
        if (pauseReasonCode) {
            url = `${url}?reason_code=${pauseReasonCode}`
        }

        return this.fetch({
            url,
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            method: 'POST',
            log: `[PAUSE SUBSCRIPTION][${apiVersion}]`,
        }).pipe(
            mergeMap(({ response }) => {
                this.logger.info(PurchaseApi.TAG, `[PAUSE SUBSCRIPTION] Succeeded`)
                return of(null).pipe(
                    // As MarketONE API V2 is splitted into several microservices, there could be a delay before getting user entitlements updated, not ideal solution better to do it here
                    delay(
                        apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2
                            ? DEFAULT_TRANSACTION_DELAY_MS
                            : 0
                    ),
                    mergeMap(() => {
                        return this.getSubscriptions()
                    })
                )
            }),
            catchError((error) =>
                throwError(
                    new VoltError(
                        (() => {
                            switch (error.code) {
                                case VoltError.codes.SUBSCRIPTION_PAUSE_FAILED.code:
                                    return VoltError.codes.SUBSCRIPTION_PAUSE_FAILED
                                default:
                                    return error
                            }
                        })(),
                        {
                            inheritedError: error,
                        }
                    )
                )
            )
        )
    }

    /**
     * @param {string} autoBillId
     * */
    resumeSubscription({ autoBillId, resetFlag = true }) {
        let url
        const apiVersion = M1ApiVersion.getApiVersion(this.config)
        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${this.config.urls.storefrontUrl}/subscription/${autoBillId}/resume?reset=${resetFlag}`
        } else {
            url = `${this.config.urls.apiUrl}/user/subscription/${autoBillId}/resume?reset=${resetFlag}`
        }
        return this.fetch({
            url,
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            method: 'POST',
            log: `[RESUME SUBSCRIPTION][${apiVersion}]`,
        }).pipe(
            mergeMap(({ response }) => {
                this.logger.info(PurchaseApi.TAG, `[RESUME SUBSCRIPTION] Succeeded`)
                return of(null).pipe(
                    // As MarketONE API V2 is splitted into several microservices, there could be a delay before getting user entitlements updated, not ideal solution better to do it here
                    delay(
                        apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2
                            ? DEFAULT_TRANSACTION_DELAY_MS
                            : 0
                    ),
                    mergeMap(() => {
                        return this.getSubscriptions()
                    })
                )
            }),
            catchError((error) =>
                throwError(
                    new VoltError(VoltError.codes.SUBSCRIPTION_RESUME_FAILED, {
                        inheritedError: error,
                    })
                )
            )
        )
    }
    /**
     * @param {string} autoBillId
     * @param {Object} metadata
     * */
    updateSubscription({
        autoBillId,
        paymentMethodId = '',
        billingPlanId = '',
        productId = '',
        campaignCode = '',
        replacedId = '',
        dryrunFlag = false,
        metadata,
    }) {
        let url
        const apiVersion = M1ApiVersion.getApiVersion(this.config)

        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${this.config.urls.storefrontUrl}/v2/subscription/${autoBillId}/update?dryrun=${dryrunFlag}`
        } else {
            url = `${this.config.urls.apiUrl}/user/v2/subscription/${autoBillId}/update?dryrun=${dryrunFlag}`
        }

        let body = {}
        if (paymentMethodId) {
            body.paymentMethodId = paymentMethodId
        }
        if (billingPlanId) {
            body.billingPlanId = billingPlanId
        }
        if (productId && replacedId) {
            body.items = [
                {
                    productId,
                    campaign_code: campaignCode,
                    replaces: replacedId,
                },
            ]
        }

        if (metadata) {
            body.metadata = metadata
        }

        return this.fetch({
            url,
            body: body,
            headers: {
                'Content-Type': 'application/json',
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            method: 'POST',
            log: `[UPDATE SUBSCRIPTION][${apiVersion}]`,
        }).pipe(
            mergeMap(({ response }) => {
                this.logger.info(PurchaseApi.TAG, `[UPDATE SUBSCRIPTION] Succeeded`)
                if (!dryrunFlag) {
                    return of(null).pipe(
                        mergeMap(() => {
                            this.getSubscriptions()
                            return of(response)
                        })
                    )
                } else {
                    return of(response)
                }
            }),
            catchError((error) => throwError(error))
        )
    }
    /**
     * Request to purchase a subscription product
     * @param {String} productId
     * @param {String} offerId
     * @param {Object} purchaseData
     * @param {Object} [purchaseData.profile] User Profile (not used)
     * @param {Object} [purchaseData.paymentMethod] Payment method to use for Purchase (e.g Pay on bill, Credit Card, etc..)
     * @param {Object} [purchaseData.billingPlan] Billing Plan to use for Purchase (Buy for 1 month, 6 month, 1 year and pay every Month or for a Year, etc..)
     * @param {String} [purchaseData.iapReceipt] (Optionally) iapReceipt is used to validate app store purchase. MarketONE has decide to reuse the purchase API to simplify the design
     * @param {String} [purchaseData.iapAppstore] (Optionally) iapAppStore is used to indicate which store is used (GOOGLE, APPLE, AMAZON)
     *
     * @returns {Observable}
     */
    validateAppStorePurchase(productId, offerId, purchaseData, iapReceipt, iapAppStore) {
        this.logger.info(
            PurchaseApi.TAG,
            `Validating to [product: ${productId}][Offer: ${offerId}] ...`
        )

        if (!iapReceipt) {
            return throwError(
                new VoltError(VoltError.codes.MISSING_IAP_RECEIPT, {
                    extraLog: 'Cannot validate appstore purchase because missing IAP Receipt',
                })
            )
        }
        this.logger.trace(PurchaseApi.TAG, `Using IAP receipt ${iapReceipt} for ${iapAppStore}...`)

        // Reuse marketONE subscribe API due to design decision
        return this.subscribe(productId, offerId, purchaseData, iapReceipt, iapAppStore)
    }

    /**
     * This method returns the list of entitlements for a USer (list of subscriptions purchased)
     * @returns {Observable<M1UserSubscription>}
     */
    _getUserSubscriptions() {
        const baseUrl = `${this.config.urls.apiUrl}/user/subscriptions`
        const offset = 0
        const { pageSize = 50 } = PurchaseApi.defaultConfig
        const {
            marketone: { retrieveOnlyActiveSubscriptions = true },
        } = this.config

        return this.recursiveListFetch(
            (_, pageSize, offset) => {
                return this.fetch({
                    url: `${baseUrl}?offset=${offset}&size=${pageSize}${
                        retrieveOnlyActiveSubscriptions ? '&status=activated' : ''
                    }`,
                    method: 'GET',
                    headers: {
                        Authorization: AuthType.BearerToken(
                            DataHelper.getInstance().getPrimaryAccessToken()
                        ),
                    },
                    log: 'GET USER SUBSCRIPTIONS',
                })
            },
            {
                pageSize,
                offset,
            }
        ).pipe(
            mergeMap((subscriptions) => {
                let tempSubscriptions = []
                subscriptions.map((sub) => {
                    let len =
                        sub?.attributes?.paymentProviderSubscriptions?.[0]?.subscriptionItems
                            ?.length
                    if (len > 1) {
                        for (let i = 0; i < len; i++) {
                            let cloned = cloneDeep(sub)
                            let { productId, plmCode, productDescription } =
                                sub.attributes.paymentProviderSubscriptions[0].subscriptionItems[i]
                            cloned.attributes.paymentProviderSubscriptions[0] = {
                                ...cloned.attributes.paymentProviderSubscriptions[0],
                                productId,
                                plmCode,
                                productDescription,
                            }

                            cloned.attributes.paymentProviderSubscriptions[0].subscriptionItems = [
                                sub.attributes.paymentProviderSubscriptions[0].subscriptionItems[i],
                            ]

                            tempSubscriptions.push(cloned)
                        }
                    } else {
                        tempSubscriptions.push(sub)
                    }
                })
                const result = (tempSubscriptions || []).map((x) => {
                    return M1UserSubscriptionFactory(x)
                })
                return of(result)
            }),
            catchError((error) =>
                throwError(
                    new VoltError(VoltError.codes.MISSING_ENTITLEMENT, {
                        inheritedError: error,
                    })
                )
            )
        )
    }

    /**
     * This method returns the list of external Packages in order to GET statuses
     * to determine if the user needs to ACTIVATE the Packages through EMAIL
     * or DEEPLINK after the purchase
     * @returns {Observable<M1UserSubscription>}
     */
    _getPartnerSubscriptions() {
        if (this.config.marketone && this.config.marketone.disablePartnerSubscriptionsApi) {
            this.logger.info(PurchaseApi.TAG, `[PARTNER SUBSCRIPTION] Disabled for this project`)
            return of([])
        }
        this.logger.info(PurchaseApi.TAG, `Getting Partner subscriptions ...`)

        let url
        const apiVersion = M1ApiVersion.getApiVersion(this.config)
        let type = this.config.partnerSubscriptionType
            ? `?type=${this.config.partnerSubscriptionType}`
            : ''
        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${this.config.urls.storefrontUrl}/partnerSubscription${type}`
        } else {
            url = `${this.config.urls.apiUrl}/user/partnerSubscription${type}`
        }
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: 'GET PARTNER SUBSCRIPTIONS',
        }).pipe(
            mergeMap(({ response }) => {
                const result = (response || []).map((x) => M1PartnerSubscriptionFactory(x))
                return of(result)
            }),
            catchError((error) => {
                if (VoltError.codes.NOT_FOUND.code === error.code) {
                    // Pretty weird backend, instead of returning empty list it returns a 404 NOT FOUND
                    return of([])
                }
                return throwError(
                    new VoltError(VoltError.codes.MISSING_ENTITLEMENT, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * Market One require a cancellation reason to unsubscribe
     * Cancel reason is a mapping between an integer ID and a string which explains the reason
     * Our UI does not offer a list to the user to select why he decides to unsubscribe
     * So for the moment we have agreed to HARD CODE a default value
     * But could be used in near future by the UI
     */
    _getCancelReason() {
        return 100
    }

    /**
     * From the titleId, retrieve property of related Content (ex: isHD)
     * and the associated product identifiers.
     *
     * @param {Object} args
     * @param {String} args.id Title identifier
     * @param {String} args.subscriptionIds Subscriptions identifiers
     * @param {Boolean} args.isTvod Title identifier
     * @param {Object} args.streams Program streams (not trailers...)
     *
     * @returns {Observable<Array<TVODProduct|Subscription>>} A list of {@link TVODProduct} or {@link Subscription} products
     */
    getProductsFromTitle({ subscriptionIds, isTvod, id: titleId, streams }) {
        if (this.config.marketone && this.config.marketone.withVodPackages && subscriptionIds) {
            if (isTvod) {
                return of([])
            }
            return this.getSubscriptionsData(subscriptionIds).pipe(
                mergeMap((subscriptions) => {
                    const updatedSubscriptions = subscriptions.map((s) => {
                        const contents = (streams || []).reduce((acc, stream) => {
                            if (stream.uri) {
                                acc[stream.uri] = {
                                    id: stream.uri,
                                    isHD: s.titles[titleId] && s.titles[titleId].isHD,
                                }
                            }
                            return acc
                        }, {})

                        return s.update({
                            titles: {
                                [titleId]: {
                                    id: titleId,
                                    contents: {
                                        ...contents,
                                    },
                                },
                            },
                        })
                    })

                    return of(updatedSubscriptions)
                })
            )
        }
        return of([])
    }

    /**
     * Get selected payment method for an account
     *
     * @param {Object} options
     * @param {string} options.paymentMethodId the payment method id got from a credit card iframe
     * @returns Observable
     */
    getSelectedPaymentMethod({ paymentMethodId }) {
        return this.fetch({
            url: `${this.config.urls.storefrontUrl}/paymentMethod/${paymentMethodId}`,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': Constants.httpHeader.ContentType.APPLICATION_JSON,
            },
            log: 'GET SELECTED PAYMENT DETAILS',
        }).pipe(
            mergeMap(({ response }) => {
                this.logger.info(PurchaseApi.TAG, `[GET SELECT PAYMENT METHOD] Succeeded`)
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(
                        error.code === VoltError.codes.UNKNOWN_API_ERROR
                            ? VoltError.codes.GET_SELECTED_PAYMENT_METHOD_ERROR
                            : error,
                        { inheritedError: error }
                    )
                )
            })
        )
    }

    /**
     * Add payment method for an account
     *
     * @param {Object} options
     * @param {string} options.paymentMethodId the payment method id got from a credit card iframe
     * @returns Observable
     */
    addPaymentMethod(payloadBody) {
        const accountId = DataHelper.getInstance().getAccountId()
        return this.fetch({
            url: `${this.config.urls.storefrontUrl}/paymentMethod/account/${accountId}`,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': Constants.httpHeader.ContentType.APPLICATION_JSON,
            },
            body: {
                ...payloadBody,
            },
            log: 'ADD NEW PAYMENT METHOD',
        }).pipe(
            mergeMap((res) => of(res)),
            catchError((error) => {
                return throwError(
                    new VoltError(
                        error.code === VoltError.codes.UNKNOWN_API_ERROR
                            ? VoltError.codes.PAYMENT_METHOD_ADDING_ERROR
                            : error,
                        { inheritedError: error }
                    )
                )
            })
        )
    }

    /**
     * Delete payment method for an account
     *
     * @param {Object} options
     * @param {string} options.paymentMethodId the payment method id got from a credit card iframe
     * @returns Observable
     */
    deletePaymentMethod({ paymentMethodId }) {
        const accountId = DataHelper.getInstance().getAccountId()
        return this.fetch({
            url: `${this.config.urls.storefrontUrl}/paymentMethod/account/${accountId}`,
            method: 'PUT',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': Constants.httpHeader.ContentType.APPLICATION_JSON,
            },
            body: {
                paymentMethodId,
                primary: false,
                active: false,
            },
            log: `DELETE PAYMENT METHOD | ID: ${paymentMethodId}`,
        }).pipe(
            mergeMap(() => of(true)),
            catchError((error) => {
                return throwError(
                    new VoltError(
                        error.code === VoltError.codes.UNKNOWN_API_ERROR
                            ? VoltError.codes.PAYMENT_METHOD_DELETING_ERROR
                            : error,
                        { inheritedError: error }
                    )
                )
            })
        )
    }

    updateSubscribe({
        autoBillId,
        dryrun,
        offerId,
        items,
        paymentMethodId,
        purchaseDryrun,
        accountId,
    }) {
        let url
        const apiVersion = M1ApiVersion.getApiVersion(this.config)
        if (apiVersion === M1ApiVersion.API_VERSION.MARKETONE_V2) {
            url = `${this.config.urls.storefrontUrl}/v2/subscription/${autoBillId}/update`
        } else {
            url = `${this.config.urls.apiUrl}/user/subscription/${autoBillId}/update`
        }
        if (dryrun) {
            url = `${url}?dryrun=${dryrun}`
        }

        let body
        if (paymentMethodId) {
            body = {
                paymentMethodId: paymentMethodId,
            }
        } else {
            // billingPlanId: offerId,
            body = {
                items: items,
            }
        }

        return this.fetch({
            url,
            body,
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': Constants.httpHeader.ContentType.APPLICATION_JSON,
            },
            method: 'POST',
            log: dryrun
                ? `[PURCHASE DRY RUN TO VERIFY COUPON][${apiVersion}]`
                : `[PURCHASE SUBSCRIPTION][${apiVersion}]`,
        }).pipe(
            mergeMap(({ response }) => {
                this.logger.info(PurchaseApi.TAG, `[PURCHASE SUBSCRIPTION] Succeeded`)
                return of(response)
            }),
            catchError((error) => {
                return throwError(error)
            })
        )
    }

    /**
     * @returns {Observable<Boolean>}
     */
    _refreshTokenAfterTransaction(userSubscriptions) {
        if (this.config.marketone && this.config.marketone.refreshTokenAfterTransaction) {
            this.logger.info(
                PurchaseApi.TAG,
                `Refresh Token after transaction to enrich user's entitlement inside the token`
            )
            return this.authApi._refreshAccessToken().pipe(
                mergeMap(() => {
                    return of(true)
                }),
                catchError(() => {
                    this.logger.warn('Error refreshing the access token after a transaction')
                    return of(false)
                })
            )
        }
        return of(true)
    }

    verifyCoupon({ productId, offerId: billingPlanId, couponId, paymentMethod } = {}) {
        if (!couponId)
            return throwError(
                new VoltError(VoltError.codes.COUPON_VALIDATION_FAILED_COUPON_IS_EMPTY)
            )

        return this.subscribe(productId, billingPlanId, {
            paymentMethod,
            couponId,
            verifyCoupon: true,
        })
    }

    // -------------------------------------------------------------------- //
    // ----[STUBBED FOR MARKET ONE - API NOT MANAGED BY THIS BACKEND]------ //
    // -------------------------------------------------------------------- //
    /* No VOD provided by Market One */
    getPurchasedTVODProducts({ limit, page }) {
        return of({ isComplete: true, products: [] })
    }

    /* No VOD provided by Market One */
    getAllPurchasedTVODProducts(limit = 15) {
        return of({ isComplete: true, products: [] })
    }

    /* No VOD provided by Market One */
    getTVODProductsData(productIds) {
        return of([])
    }

    /* No VOD provided by Market One */
    purchaseTVOD(productId, offerId) {
        return of({})
    }

    /**
     * Redeem a voucher code
     *
     * @param {Object} options
     * @param {String} options.code value of voucherCode
     * @returns {Observable<Boolean>} returns boolean
     */
    redeemVoucherCode = ({ code }) => {
        return of(false)
    }

    sendPurchaseOTP = () => throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))
    validatePurchaseOTP = () => throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))
    getUserWallet = () => throwError(new VoltError(VoltError.codes.FEATURE_NOT_AVAILABLE))

    getBillingDetail = ({ key, value }) => {
        const url = `${this.config.urls.storefrontUrl}/v3/subscription?${key}=${value}`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `GET BILLING DETAIL METHOD| ${key?.toUpperCase()}: ${value}`,
        }).pipe(
            mergeMap((res) => of(res)),
            catchError((error) => {
                return throwError(error)
            })
        )
    }

    geteLatestConsentUrl = () => {
        let url = `${this.config.urls.ifsApiUrl}/msapi/termsandconditions/v1/termscontent/AE/v1`
        return this.fetch({
            url,
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': Constants.httpHeader.ContentType.APPLICATION_JSON,
            },
            method: 'GET',
            log: '',
        }).pipe(
            mergeMap(({ response }) => {
                return of(response)
            }),
            catchError((error) => {
                return of(error)
            })
        )
    }

    getPurchaseActivity = (accountId, page, limit) => {
        return this.fetch({
            url: `${this.config.urls.extensionApiEndPoint}/v1/activity/purchase/${accountId}?page=${page}&size=${limit}`,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `GET PURCHASE ACTIVITY DETAIL METHOD| ID: ${accountId}`,
        }).pipe(
            mergeMap((res) => of(res)),
            catchError((error) => error)
        )
    }

    subscriptionActionMethod = (payloadBody) => {
        return this.fetch({
            url: `${this.config.urls.extensionApiEndPoint}/dcm/storefront/v1/subscription/action`,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            body: {
                ...payloadBody,
            },
            log: 'Subscription Action Method',
        }).pipe(
            mergeMap((res) => of(res)),
            catchError((error) => {
                return throwError(error)
            })
        )
    }

    getInstallmentBilling = (accountId, subscriptionId) => {
        return this.fetch({
            url: `${this.config.urls.extensionApiEndPoint}/dcm/storefront/v1/account/${accountId}/subscription/${subscriptionId}/inquiredetailedhistory`,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: ``,
        }).pipe(
            mergeMap((res) => of(res)),
            catchError((error) => {
                return throwError(error)
            })
        )
    }

    getProratedtRefund = (accountId, subscriptionId) => {
        return this.fetch({
            url: `${this.config.urls.extensionApiEndPoint}/dcm/storefront/v1/account/${accountId}/subscription/${subscriptionId}/prorated/refund`,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: 'Prorated Refound Method',
        }).pipe(
            mergeMap((res) => of(res)),
            catchError((error) => {
                return throwError(error)
            })
        )
    }

    // -------------------------------------------------------------------- //
    // ---------------------------END STUB -------------------------------- //
    // -------------------------------------------------------------------- //
}

/**
 * @typedef {Object} SubscriptionsList
 * @property {Boolean} isComplete Flag indicating if more results are available
 * @property {Array<Subscription>} products A list of products of type {@link Subscription}
 */
