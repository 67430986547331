import config from './production.js'

const conf = {
    ...config,
    carePortal: {
        urls: {
            adminAuthUrl: 'https://attustest01-ulm-pdt.m1amdocs.io/',
        },
        agentClientId: 'HaloCTestRP',
        ssoClientId: 'haloeFederationClientID',
        ssoClientSecret: '',
        OAuthParam: {
            scope: 'openid profile',
            extras: {},
        },
    },
    urls: {
        ...config.urls,
        apiUrl: 'https://attustest01-ulm-pdt.m1amdocs.io/platform/rest/v85',
        authUrl: 'https://attustest01-ulm-pdt.m1amdocs.io',
        userPreferencesUrl: 'https://attustest01-ulm-pdt.m1amdocs.io/customData',
        storefrontUrl: 'https://attustest01-ulm-pdt.m1amdocs.io/storefront',
        emi: {
            baseUrl: 'https://attus-curation-api-pre001.pdt.ifs.m1amdocs.io',
            homePath: '3.0/{population}/1',
            defaultPopulation: 'pdt',
        },
        proxyUrl: 'https://attus-api-pre001.pdt.ifs.m1amdocs.io/v3.1',
        ifsApiUrl: 'https://plm-prodtest.m1amdocs.io/el/pdt1',
        iframePaymentUrl: 'https://test3.att.com/acctmgmt/paymentmgmt/paymentmethods',
    },
    env: 'preprod',
    firebaseConfig: {
        enabled: false,
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
        measurementId: '',
    },
    clientId: '9c7406d169de4c89a3c39ffc9ddaaec6',
}

export default conf
